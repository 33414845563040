import moment from 'moment';
import { createAction } from 'redux-actions';
import { ROUTES } from 'constants/routes';
import { EVENTS } from 'constants/analytics';

export const ANALYTICS = 'ANALYTICS';
const analytics = createAction(ANALYTICS);

/**
 * Get Current Analytics timestamp
 * @return {string} timestamp
 */
const getTimestamp = () => {
    return moment().format();
};

/**
 * Returns the page type for analytics based on the current path
 * @param {string} pathname
 * @return {string} page type
 */
export const getPageType = ({ pathname }) => {
    if (pathname === ROUTES.PRODUCT_SEARCH) {
        return 'search_results';
    } else if (pathname.startsWith(`${ROUTES.PRODUCT_DESCRIPTION}/P`)) {
        return 'pdp';
    } else if (pathname.startsWith(`${ROUTES.PRODUCT_LISTING}/`)) {
        return 'plp';
    }

    return pathname;
};

/**
 * Get User information from state for analytics
 * @param {object} state
 * @return {object} user information
 */
const getUserInformation = () => {
    // user information when not authenticated is very limited
    return {
        gigyaId: undefined,
        userId: undefined,
        staffId: undefined,
        impersonation: 0,
        userType: undefined,
        accountType: undefined,
        checkin: 0,
        checkin_type: undefined,
        checkin_method: undefined,
        tourist: 0,
        repeatCheckin: 0,
        locale: 'en',
        region_chanel: 'us',
        loggedin: 0
    };
};

/**
 * Analytics tag action
 * @param {object} payload
 * @param {object} config
 * @return {function(*, *): *} Promise
 */
export const analyticsTag = (payload, config = {}) => (dispatch, getState) => {
    const {
        globalLayout: { webView }
    } = getState();

    const timestamp = { timestamp: getTimestamp() };

    const { url, ...analyticsPayload } = payload;
    let userInformation = {};

    // send webview dimension
    analyticsPayload.webview = webView;

    if (Object.prototype.hasOwnProperty.call(config, 'userInfo')) {
        userInformation = getUserInformation(getState());
    }

    // if there was a url property in the analytics tag, strip it and send it as a separate event
    if (url) {
        const gigyaId = getUserInformation(getState()).gigyaId;

        dispatch(
            analytics({ event: EVENTS.Pageview, url, gigyaId, ...timestamp })
        );
        if (config.urlOnly) {
            return;
        }
    }

    return dispatch(
        analytics({ ...timestamp, ...analyticsPayload, ...userInformation })
    );
};
