import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageMobileHeader from 'components/PageMobileHeader/PageMobileHeader';
import styled, { createGlobalStyle, css } from 'styled-components';
import { connect } from 'react-redux';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled';
import { resetLayout, setHeaderColor, setBackgroundColor, setStaffTheme, invertHeaderIcons, setHeaderIcons, setHideFooter } from 'reducers/globalLayout';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import { styledProps, rem } from 'core/styled';
import { LAYER } from 'constants/layers';
import formatPrismicData from 'core/utils/formatPrismicData';
import Loader from 'components/GlobalComponents/Loader/Loader';
import { PRISMIC_DATA_FORMAT } from 'constants/prismic';
import { analyticsTag } from 'reducers/analytics';
import { withRouter } from 'react-router-dom';

const HAMBURGER_MENU_OVERLAY_ID = 'hamburger-menu-overlay';

const AppWrapper = styled.div``;

const LayoutOuter = styled.div`
  height: ${({ isFragrance }) => isFragrance ? '100vh' : 'auto'};
  position: ${({ disableScroll, showMenu, preventModalOpenScrollToTop }) => (disableScroll && !preventModalOpenScrollToTop) || showMenu ? 'fixed' : 'relative'};
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: left 0.4s linear;
  overflow: ${({ disableScroll }) => disableScroll ? 'hidden' : 'visible'};
`;

const ContentWrapper = styled.main`
    position: relative;
    width: 100vw;
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding-top: ${({ overlayHeader, isImpersonating }) => isImpersonating ? overlayHeader ? rem(140) : rem(200) : overlayHeader ? '0' : styledProps('global', 'headerHeight')};
    z-index: ${LAYER.BODY};
    min-height: 100%;
    overflow: ${({ disableScroll }) => disableScroll ? 'hidden' : 'visible'};
    
    ${greaterThan(breakpoints.small)(css`
        padding-top: ${({ overlayHeader, isImpersonating }) => isImpersonating ? overlayHeader ? rem(140) : rem(220) : overlayHeader ? '0' : styledProps('global', 'desktopHeaderHeight')};
    `)};
    
    ${greaterThan(breakpoints.medium)(css`
        width: 100%;
    `)};
`;

const AdaTitle = styled.p`
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
`;

export const HideCookieLawStyle = createGlobalStyle`
    #onetrust-banner-sdk {
        display: none;
    }
`;

class LayoutContainer extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        resetLayout: PropTypes.func.isRequired,
        setHeaderColor: PropTypes.func.isRequired,
        setBackgroundColor: PropTypes.func.isRequired,
        isAtelierReady: PropTypes.bool.isRequired,
        disableScroll: PropTypes.bool.isRequired,
        preventModalOpenScrollToTop: PropTypes.bool.isRequired,
        setStaffTheme: PropTypes.func.isRequired,
        invertHeaderIcons: PropTypes.func.isRequired,
        setHeaderIcons: PropTypes.func.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        push: PropTypes.func.isRequired,
        isForm: PropTypes.bool,
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool,
        headerColor: PropTypes.string,
        headerInvertedIconsColor: PropTypes.bool,
        pageBackgroundColor: PropTypes.string,
        overlayHeader: PropTypes.bool,
        footerData: PropTypes.object,
        showHeaderLogo: PropTypes.bool,
        showFullLogo: PropTypes.bool,
        isMobileKeyboardOpen: PropTypes.bool.isRequired,
        headerIcons: PropTypes.string,
        disableHeaderShrink: PropTypes.bool,
        isImpersonating: PropTypes.bool,
        headerBackground: PropTypes.string,
        showEmptyFooter: PropTypes.bool,
        isFragrance: PropTypes.bool,
        hideFooter: PropTypes.bool.isRequired,
        setHideFooter: PropTypes.func.isRequired,
        automaticSearchExpansion: PropTypes.bool,
        isHeaderShrunk: PropTypes.bool,
        hideNavFooter: PropTypes.bool,
        ariaPageName: PropTypes.string,
        webView: PropTypes.bool.isRequired,
        cartOpen: PropTypes.bool.isRequired,
        showQuickShopHeader: PropTypes.oneOf(['collapsed', 'expanded']),
        showQuickShopFooter: PropTypes.bool
    };

    static defaultProps = {
        showHeader: true,
        showFooter: true,
        overlayHeader: false,
        showHeaderLogo: true,
        pageBackgroundColor: '#ffffff',
        showFullLogo: false,
        headerInvertedIconsColor: false,
        disableHeaderShrink: false,
        showEmptyFooter: true,
        automaticSearchExpansion: false,
        hideNavFooter: false
    };

    state = {
        headerData: {},
        showHamburgerMenu: false,
        showCartMenu: false,
        closingMenu: false,
        closingCart: false
    };

    adaTitle = React.createRef();
    adaLoader = React.createRef();

    componentDidMount() {
        // If staff, then set staff theme
        const { setHideFooter, showFooter } =  this.props;

        // update header color, if needed
        if (this.props.headerColor) {
            this.props.setHeaderColor(this.props.headerColor);
        }

        // invert header icons, if needed
        if (this.props.headerInvertedIconsColor) {
            this.props.invertHeaderIcons();
        }

        // invert header icons, if needed
        if (this.props.headerIcons) {
            this.props.setHeaderIcons(this.props.headerIcons);
        }

        // update page background color, if needed
        if (this.props.pageBackgroundColor) {
            this.props.setBackgroundColor(this.props.pageBackgroundColor);
        }

        if (this.props.isAtelierReady && this.props.ariaPageName && this.adaTitle.current) {
            this.adaTitle.current.focus();
        }

        if (!this.props.isAtelierReady && this.adaLoader.current) {
            this.adaLoader.current.focus();
        }

        setHideFooter(!showFooter);

    }

    /**
     * Resets the body element style to be able to scroll after this component is unmounted
     */
    resetBodyStyle = () => {
        document.body.style.overflow = 'visible';
        document.body.style.height = 'auto';
        document.body.style.webkitOverflowScrolling = 'touch';
        document.body.style.overflowY = 'scroll';
        document.body.style.position = null;
    };

    componentWillUnmount() {
        this.resetBodyStyle();
        this.props.resetLayout();
    }

    componentDidUpdate(prevProps, prevState) {
        const { disableScroll, preventModalOpenScrollToTop, isAtelierReady, ariaPageName, showFooter, setHideFooter } = this.props;
        const { showHamburgerMenu, showCartMenu } = this.state;


        // If disabling scroll or toggling hamburger menu, then adjust body element styles
        if (prevProps.disableScroll !== disableScroll || prevState.showHamburgerMenu !== showHamburgerMenu || prevState.showCartMenu !== showCartMenu) {
            // set overflow state of body to prevent whole page scroll
            // set height so that overflow property is respected
            document.body.style.overflow = disableScroll || showHamburgerMenu || showCartMenu ? 'hidden' : 'visible';
            document.body.style.height = disableScroll || showHamburgerMenu || showCartMenu ? '100vh' : 'auto';
            document.body.style.webkitOverflowScrolling = disableScroll ? 'hidden' : 'touch';
            document.body.style.overflowY = disableScroll ? 'hidden' : 'scroll';
            document.body.style.position = disableScroll && !preventModalOpenScrollToTop ? 'fixed' : null;
        }

        if (!prevProps.isAtelierReady && isAtelierReady) {
            if (ariaPageName && this.adaTitle.current) {
                this.adaTitle.current.focus();
            }
        }

        if (!isAtelierReady && document.activeElement.id !== 'ada--loader' && this.adaLoader && this.adaLoader.current) {
            this.adaLoader.current.focus();
        }

        if (prevProps.showFooter !== showFooter) {
            setHideFooter(!showFooter);
        }
    }

    /**
     * Disable touch move to prevent scrolling of div on iOS (which apparently doesn't respect overflow: hidden like everyone else does)
     * Some more details on that here: https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
     * @param {object} e
     */
    disableTouchMove = (e) => {
        if (e.target.id === HAMBURGER_MENU_OVERLAY_ID) {
            e.preventDefault();
        }
    };

    _renderADATitle = ariaPageName => {
        return (
            <AdaTitle tabIndex={'-1'} ref={this.adaTitle}>{ariaPageName} Page - Atelier Chanel Beauté</AdaTitle>
        );
    };

    render() {
        const {
            showHeader,
            backgroundColor,
            isAtelierReady,
            disableScroll,
            preventModalOpenScrollToTop,
            showFullLogo,
            isMobileKeyboardOpen,
            isFragrance,
            ariaPageName,
            webView,
            showQuickShopHeader,
        } = this.props;

        // webview related
        const nonFragranceWebView = webView && !isFragrance;
        const displayHeader = !nonFragranceWebView && showHeader && !showQuickShopHeader;

        if (isAtelierReady) {
            //TODO: Events page has something strange happening which causes the footer cut edges offset to not work as expected on mobile. Below isEventsPage var is a hack, but it works!
            return (
                <AppWrapper isFragrance={isFragrance} disableScroll={disableScroll} disableMobileScroll={isMobileKeyboardOpen}>
                    {ariaPageName && this._renderADATitle(ariaPageName)}
                    <LayoutOuter backgroundColor={backgroundColor} disableScroll={disableScroll} preventModalOpenScrollToTop={preventModalOpenScrollToTop} isFragrance={isFragrance}>
                        {displayHeader && <PageMobileHeader showLogo showFullLogo={showFullLogo} />}
                        <ContentWrapper backgroundColor={backgroundColor} disableScroll={disableScroll} role={'main'} tabIndex={-1}>
                            {this.props.children}
                        </ContentWrapper>
                    </LayoutOuter>
                </AppWrapper>
            );
        }

        // Per design, render header even while app is loading
        return (
            <div>
                <AppWrapper isFragrance={isFragrance} disableScroll={disableScroll} disableMobileScroll={isMobileKeyboardOpen}>
                    {displayHeader && <PageMobileHeader showLogo showFullLogo={showFullLogo} />}
                    <p id={'ada--loader'} className={'sr-only'} tabIndex={'-1'} ref={this.adaLoader}>Loading in progress</p>
                    <Loader fullScreen/>
                </AppWrapper>
            </div>
        );
    }
}

const mapStateToProps = ({ globalLayout: { headerBackground, backgroundColor, disableScroll, preventModalOpenScrollToTop, isHeaderStaffTheme, isMobileKeyboardOpen, isFragrance, hideFooter, webView, cartOpen }, prismic }, { headerInvertedIconsColor }) => ({
    headerBackground,
    backgroundColor,
    disableScroll,
    preventModalOpenScrollToTop,
    hideFooter,
    headerInvertedIconsColor: headerInvertedIconsColor || isHeaderStaffTheme,
    footerData: prismic.isAtelierReady ? formatPrismicData(prismic, PRISMIC_DATA_FORMAT.FOOTER) : {},
    isAtelierReady: prismic.isAtelierReady,
    isMobileKeyboardOpen,
    isFragrance,
    webView,
    cartOpen
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push, resetLayout, setHeaderColor, setBackgroundColor, setStaffTheme, invertHeaderIcons, setHeaderIcons, analyticsTag, setHideFooter },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
    LayoutContainer
));

