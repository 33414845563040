import React, { Component, Suspense, lazy } from 'react';
import styled from 'styled-components';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ROUTES } from 'constants/routes';
import { META_DATA, DEFAULT_META_DATA, ARTIST_NAME } from 'constants/metaData';
import { fetchAtelier } from 'reducers/prismicFetch';
import { PRISMIC_PAGE_IDS } from 'constants/prismic';
import PropTypes from 'prop-types';
import Preview from 'containers/PrismicContainer/Preview';
import { Helmet } from 'react-helmet';
import 'core/styled/fonts.css';
import { GlobalStyle } from 'core/styled/global';
import SuspensePage from 'pages/SuspensePage/SuspensePage';
import Loader from 'components/GlobalComponents/Loader/Loader';

const HomePage = lazy(() => import('pages/HomePage/HomePage'));

const RoutesFlexContainer = styled.div`
    display: flex;
    height: 100%;

    > div {
        flex: 1;
    }
`;

const attachTabHandler = () => {
    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            document.body.classList.add('atelier-tabbing');

            window.removeEventListener('keydown', handleFirstTab);
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    }

    function handleMouseDownOnce() {
        document.body.classList.remove('atelier-tabbing');

        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    }

    window.addEventListener('keydown', handleFirstTab);
};

class App extends Component {

    static propTypes = {
        fetchAtelier: PropTypes.func.isRequired,
        location: PropTypes.object,
        currentMUAData: PropTypes.object
    };

    state = {
        metaData: {},
        loading: false
    };

    constructor(props) {
        super(props);

        attachTabHandler();
    }

    componentDidMount = () => {
        const { fetchAtelier } = this.props;
        fetchAtelier(PRISMIC_PAGE_IDS.ATELIER_SOHO);

        this.setMetaData();

    };

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location || prevProps.currentMUAData !== this.props.currentMUAData) {
            this.setMetaData();
        }
    }

    /**
     * Set meta data for given route
     */
    setMetaData() {
        const ROUTE = this.props.location.pathname;
        // check if there is meta data for specific page
        const pageMetaData = META_DATA.find(data => ROUTE.includes(data.route));
        // if not, then assign the page the default meta data
        let metaData = pageMetaData ? pageMetaData : DEFAULT_META_DATA;

        // Right now, we're only dynamically replacing the artist's name. If that changes, we might need a more scalable solution to this.
        if (metaData.title.includes(ARTIST_NAME)) {
            const artistName = this.props.currentMUAData.name;

            metaData = {
                ...metaData,
                title: metaData.title.replace(ARTIST_NAME, artistName),
                description: metaData.description.replace(ARTIST_NAME, artistName)
            };
        }

        this.setState({
            metaData
        });
    }

    render() {
        const { metaData, loading } = this.state;

        if (loading) {
            return (
                <>
                    <Helmet>
                        <title>{metaData.title}</title>
                        <meta name="description" content={metaData.description} />
                        <meta property="og:title" content={DEFAULT_META_DATA.title} />
                        <meta property="og:description" content={DEFAULT_META_DATA.description} />
                        <meta property="og:image" content={DEFAULT_META_DATA.image} />
                    </Helmet>
                    <Loader />
                </>
            );
        }

        return (
            <RoutesFlexContainer>
                <Helmet>
                    <title>{metaData.title}</title>
                    <meta name="description" content={metaData.description} />
                    <meta property="og:title" content={DEFAULT_META_DATA.title} />
                    <meta property="og:description" content={DEFAULT_META_DATA.description} />
                    <meta property="og:image" content={DEFAULT_META_DATA.image} />
                </Helmet>
                <Suspense fallback={<SuspensePage/>}>
                    <Switch>
                        <Route path={ROUTES.HOME} exact component={HomePage} />
                        <Route exact path={ROUTES.PRISMIC_PREVIEW} render={routeProps => <Preview {...routeProps} />} />
                        <Route>
                            <Redirect to path={ROUTES.HOME} />
                        </Route>
                    </Switch>
                </Suspense>
                <GlobalStyle />
            </RoutesFlexContainer>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchAtelier },
        dispatch
    );

export default withRouter(connect(null, mapDispatchToProps)(App));
