import { combineReducers } from 'redux';
import globalLayout from './globalLayout';
import prismicReducer from './prismicFetch';

const reducers = (routerReducer) => combineReducers({
    router: routerReducer,
    prismic: prismicReducer,
    globalLayout
});

export default reducers;
