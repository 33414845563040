const {
    REACT_APP_API_HOST: API_HOST = '',
    REACT_APP_API_PATH: API_PATH = '',
    REACT_APP_PRISMIC_API_URL: PRISMIC_API_URL = '',
    REACT_APP_PRISMIC_ACCESS_TOKEN: PRISMIC_ACCESS_TOKEN = '',
} = process.env; // eslint-disable-line no-undef


export const CONFIG = {
    API_URL: `${API_HOST}${API_PATH}`,
    PRISMIC_API_URL,
    PRISMIC_ACCESS_TOKEN,
};
