import { LOCATION_CHANGE } from 'redux-first-history';
import log from 'core/log';

export const FETCH = 'FETCH';
export const FETCH_RESET = 'FETCH_RESET';
export const FETCH_PENDING = 'FETCH_PENDING';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';

/**
 * Middleware that handles all fetch redux actions
 * @param {object} store - Redux store
 * @return {function(next: function): function(action: object)} higher-order function
 */
const fetchMiddleware = ({ dispatch }) => next => action => {
    if (action.type === LOCATION_CHANGE) {
        next(action);

        return dispatch({
            type: FETCH_RESET
        });
    }

    if (action.type !== FETCH) {
        return next(action);
    }

    const {
        payload: { endpoint, prefix, options },
        meta
    } = action;
    const typePrefix = prefix ? `${prefix}/` : '';

    dispatch({
        type: `${typePrefix}${FETCH_PENDING}`
    });

    const headers = {
        'Content-Type': 'application/json'
    };

    let isBlob = false;

    if (options) {
        // This triggers the back-end to respond with both
        // no-cache and legacy no-cache headers for cross-browser (IE) support
        if (options.noCache === true) {
            headers['Cache-Control'] = 'no-cache';
            delete options.noCache;
        }

        if (options.blob) {
            isBlob = true;
            delete options.blob;
        }
    }

    return fetch(endpoint, {
        credentials: 'include',
        headers: headers,
        ...options
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    let json;
                    try {
                        json = JSON.parse(error);
                        json.status = response.status;
                    } catch (e) {
                        json = {
                            message: error,
                            status: response.status
                        };
                    }

                    dispatch({
                        type: `${typePrefix}${FETCH_ERROR}`,
                        payload: json
                    });

                    throw json;
                });
            }

            if (isBlob) {
                return response.blob();
            }

            return response.text();
        })
        .then(text => {
            let json;
            try {
                if (isBlob) {
                    const blobToBase64 = blob =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = () => {
                                dispatch({
                                    type: `${typePrefix}${FETCH_SUCCESS}`,
                                    payload: reader.result,
                                    meta
                                });
                                return resolve(reader.result);
                            };
                            reader.onerror = err => {
                                log.info('Error reading blob response');
                                return reject(err);
                            };
                            reader.readAsDataURL(blob);
                        });
                    return blobToBase64(text).then(result => result);
                } else {
                    json = JSON.parse(text);
                }
            } catch (e) {
                json = text;
            }

            dispatch({
                type: `${typePrefix}${FETCH_SUCCESS}`,
                payload: json,
                meta
            });

            return json;
        })
        .catch(err => {
            if (err.name === 'AbortError') {
                log.info(err);
            } else {
                throw err;
            }
        });
};

// eslint-disable-next-line
export default fetchMiddleware;
