import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import fetch from './fetch';
import analytics from './analytics';
import prismicFetch from 'middlewares/prismicFetch';

const middlewares = (routerMiddleware) => composeWithDevTools(
    applyMiddleware(analytics, thunk, fetch, prismicFetch, routerMiddleware)
);

export default middlewares;
